import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ContactPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    if (params && params.success && params.success === 'true') {
      Swal.fire(
        'Contact Form Submitted!',
        'We will be in touch with you shortly!',
        'success'
      );
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    // eslint-disable-next-line
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        navigate(form.getAttribute('action'), { replace: true });
        window.location.reload();
      })
      // eslint-disable-next-line
      .catch(error => alert(error));
  };

  render() {
    const { title, meta_title, meta_description } = this.props;
    return (
      <div>
        <Helmet>
          <title>{meta_title}</title>
          <meta name="description" content={meta_description} />
        </Helmet>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h1 className="is-size-2 has-text-centered title">{title}</h1>
                <div
                  className="card"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '600px',
                    boxShadow:
                      '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                    borderRadius: '4px',
                    padding: '1rem',
                  }}
                >
                  <form
                    name="contact"
                    method="post"
                    action="/contact?success=true"
                    encType="application/x-www-form-urlencoded"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field">
                      <label className="label">Name</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          placeholder="Full Name"
                          name="name"
                          id="name"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Email</label>
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          placeholder="Email"
                          name="email"
                          id="email"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Message</label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Message"
                          name="message"
                          id="message"
                          rows="6"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div
                      className="field is-grouped"
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <div className="control">
                        <button
                          className="button is-text"
                          type="reset"
                          style={{ textDecoration: 'none' }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="control">
                        <button
                          className="button is-primary"
                          type="submit"
                          disabled={
                            !this.state.name ||
                            !this.state.email ||
                            !this.state.message
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
};

export default ContactPageTemplate;
